import optelmaImage from "../assets/img/portfolio/optelma.png"
import gerenciaImage from "../assets/img/portfolio/gerencia.png"
import VocaddImage from "../assets/img/portfolio/Vocadd.png"
import moveUImage from '../assets/img/portfolio/MoveU.jpg'
import eventImage from "../assets/img/portfolio/Event.webp"
import safai from "../assets/img/Screenshot (24) copy.png"
import puppy from "../assets/img/imgonline-com-ua-twotoone-ykRKwYwKYTjn.jpg"
import xyxx from "../assets/img/imgonline-com-ua-twotoone-pzngpbLqHUJfEe.jpg"
import shopi from "../assets/img/xyxx.jpg"
import online from "../assets/img/imgonline-com-ua-twotoone-O4kBPGyPda8uE.jpg"
import market1 from "../assets/img/imgonline-com-ua-twotoone-IpqbOYyW7GbH.jpg"
import podcast from "../assets/img/Screenshot 2024-07-15 at 12.43.14 AM.png"

export const portfolioData = [
  {
    id: 1,
    image: xyxx,
    title: "Shoppi App",
    description: "The Shoppi App simplifies shopping. Browse products, create wishlists, track orders, and enjoy personalized recommendations, secure payments, and exclusive deals all in one place.",
    details: {
      title: "Shoppi App Case study",
      description: "The Shoppi App is a user-friendly shopping platform that simplifies online shopping. It allows users to browse products, create wishlists, track orders, and receive personalized recommendations. With secure payments and exclusive deals, Shoppi enhances the online shopping experience.",
      technology: {
        backend: ["Ruby on Rails (API)"],
        frontend: ["React Native (Mobile App)"],
        styling: ["CSS", "Tailwind CSS", "Bootstrap"],
        markup: ["HTML"],
        databases: ["PostgreSQL"],
      },
      challenges: [
        "Creating a smooth and responsive shopping experience across devices.",
        "Ensuring safe and smooth payment transactions.",
        "Handling large datasets for product listings and user interactions without slowdowns."
      ],
      solutions: [
        "Implemented React Native for a cross-platform mobile app, ensuring a native-like experience on both iOS and Android. Used Tailwind CSS & Bootstrap for UI consistency and responsiveness.",
        "Integrated WebSockets and API polling in Ruby on Rails to push real-time updates to the React Native frontend.",
        "Used Stripe API for secure payment processing, implemented encryption for sensitive data, and followed best security practices.",
        "Implemented caching strategies and optimized database queries in Rails using PostgreSQL indexing and Active Record optimizations."
      ]
    },
  },
  {
    id: 2,
    image: shopi,
    title: "SafaiXYZ App",
    description: "The SafaiXYZ App is a user-friendly mobile application designed to streamline waste management and recycling. It allows users to schedule pickups, track collection times, and receive notifications for waste disposal services.",
    details:{
      "title": "SafaiXYZ Case Study",
      "description": "The SafaiXYZ App is a user-friendly mobile application designed to streamline waste management and recycling. It enables users to schedule waste pickups, track collection times, and receive notifications for disposal services, making waste management more efficient and environmentally friendly.",
      "technology": {
        "backend": ["Ruby on Rails (API)"],
        "frontend": ["React Native (Mobile App)"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["sqlite3"]
      },
      "challenges": [
        "Managing multiple waste pickup requests, scheduling conflicts, and tracking collection times.",
        "Ensuring secure authentication and seamless app performance.",
        "Keeping users informed about pickup reminders and service updates."
      ],
      "solutions": [
        "Implemented a scheduling algorithm in Rails to optimize routes and avoid conflicts. Used WebSockets for real-time updates on collection status.",
        "Used Devise authentication for user logins and optimized React Native UI with Tailwind CSS and Bootstrap for a smooth user experience.",
        "Integrated Firebase Cloud Messaging (FCM) for push notifications, ensuring timely alerts about scheduled pickups and service delays."
      ]
    }
  },
  {
    id: 3,
    image: puppy,
    title: "ABC Puppy Web",
    description: "The ABC Puppy web is a fun and interactive mobile application for puppy lovers. It offers essential tips on training, health care, and grooming, along with a wealth of playful activities and games for puppies.",
    details:{
      "title": "ABC Puppy Case Study",
      "description": "ABC Puppy Web is a fun and interactive web application designed for puppy lovers. It provides essential tips on training, health care, and grooming, along with engaging activities and games to keep puppies entertained. The platform serves as an all-in-one resource for pet owners, ensuring they have the best guidance for raising happy and healthy puppies.",
      "technology": {
        "backend": ["Ruby on Rails (API)"],
        "frontend": ["React (Web Application)"],
        "styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["sqlite3"]
      },
      "challenges": [
        "Creating an intuitive and visually appealing interface that keeps users engaged.",
        "Managing and categorizing extensive training, health care, and grooming information without overwhelming users.",
        "Providing real-time updates for new puppy care tips and enabling user interactions like comments and likes.",
        "Ensuring the web application functions smoothly across different devices and screen sizes."
      ],
      "solutions": [
        "Used React components for a dynamic user experience and Tailwind CSS & Bootstrap for a responsive and clean UI design.",
        "Implemented a well-structured navigation system, including search filters and categorized sections, allowing users to find relevant information quickly.",
        "Integrated WebSockets for live updates and used Rails’ API to handle user-generated content efficiently.",
        "Designed the UI using Tailwind CSS & Bootstrap to ensure responsiveness and seamless performance on both desktops and mobile devices."
      ]
    }
  },
  {
    id: 4,
    image: market1,
    title: "Market Place App",
    description: "The Market Place App is a versatile mobile platform that connects buyers and sellers effortlessly. Explore a wide range of products, from local goods to global items, with features for secure transactions, reviews, and personalized recommendations.",
    details:{
      "title": "Market Place App Case Study",
      "description": "The Market Place App is a versatile mobile platform that seamlessly connects buyers and sellers. Users can explore a wide range of local and global products, make secure transactions, read reviews, and enjoy a personalized shopping experience.",
      "technology": {
        "backend": ["Ruby on Rails (API)"],
        "frontend": ["React Native (Mobile Application)"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["PostgreSQL"]
      },
      "challenges": [
        "Ensuring safe and smooth payment processing for buyers and sellers.",
        "Handling a growing number of users and product listings without affecting performance.",
        "Building trust between buyers and sellers through ratings and reviews.",
        "Enabling seamless communication between users for inquiries and negotiations."
      ],
      "solutions": [
        "Integrated Stripe and PayPal APIs to handle transactions securely, along with encryption for sensitive data.",
        "Optimized database queries using indexing, implemented caching strategies, and used background jobs for heavy processing tasks.",
        "Developed a review and rating system where buyers can leave feedback after a purchase, helping ensure transparency.",
        "Used WebSockets and ActionCable in Rails to implement real-time messaging within the app."
      ]
    }
  },
  {
    id: 5,
    image: online,
    title: "Online Payment App",
    description: "The Online Payment App simplifies transactions by allowing users to send and receive money securely from their mobile devices. With features like instant transfers, bill payments, and transaction tracking, it offers a convenient and safe way to manage your finances anytime, anywhere.",
    details:{
      "title": "Online Payment App Case Study",
      "description": "The Online Payment App is a secure and efficient platform that enables users to send and receive money, pay bills, and track transactions directly from their mobile devices. With a focus on convenience and security, the app ensures seamless financial management anytime, anywhere.",
      "technology": {
        "backend": ["Ruby on Rails (API)"],
        "frontend": ["React Native (Mobile Application)"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["PostgreSQL"]
      },
      "challenges": [
        "Protecting sensitive financial data from fraud and unauthorized access.",
        "Ensuring instant money transfers without delays.",
        "Supporting multiple payment gateways for seamless transactions.",
        "Providing a smooth and intuitive experience across different devices."
      ],
      "solutions": [
        "Implemented end-to-end encryption (SSL/TLS), used token-based authentication (JWT/OAuth), and followed PCI DSS compliance for secure transactions.",
        "Integrated webhooks and background jobs (Sidekiq) in Rails to process transactions asynchronously while providing real-time status updates to users.",
        "Integrated Stripe, PayPal, and Razorpay APIs, allowing users to choose their preferred payment method.",
        "Used React Native’s responsive UI components and styled with Tailwind CSS & Bootstrap to ensure an easy-to-navigate interface."
      ]
    }
  },
  {
    id: 6,
    image: podcast,
    title: "PodCast App",
    description: "A podcast app that allows users to discover, subscribe to, and listen to their favorite podcasts. The app features an intuitive interface for browsing various categories, personalized recommendations, offline listening, and easy management of subscriptions.",
    details:{
      "title": "Podcast App Case Study",
      "description": "The Podcast App is designed for users to discover, subscribe to, and listen to their favorite podcasts with ease. The app features an intuitive interface, supports offline listening, provides personalized recommendations, and allows users to efficiently manage their subscriptions.",
      "technology": {
        "backend": ["Ruby on Rails (API)"],
        "frontend": ["React Native (Mobile Application)"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"]
      },
      "challenges": [
        "Ensuring smooth audio streaming and enabling offline listening without interruptions.",
        "Handling a vast collection of podcasts with different categories and sources.",
        "Keeping users engaged by notifying them about new episodes.",
        "Allowing users to play podcasts in the background while using other apps."
      ],
      "solutions": [
        "Integrated ExoPlayer (Android) and AVPlayer (iOS) for efficient audio streaming and implemented local storage to allow users to download and play podcasts offline.",
        "Implemented RESTful APIs with pagination, optimized database queries, and used caching mechanisms to improve performance.",
        "Implemented push notifications using Firebase Cloud Messaging (FCM) and created a subscription model to notify users when new episodes are available.",
        "Used React Native Background Fetch and Media Controls API to enable background playback and lock screen controls for seamless user experience."
      ]
    }
  },
  {
    id: 7,
    image: safai,
    title: "SAFAI XYZ",
    description: "Safai XYZ offers comprehensive cleaning services tailored to residential and commercial spaces. With a focus on efficiency and quality, we provide customizable cleaning solutions that ensure a pristine environment for our clients.",
    details:{
      "title": "Safai XYZ Website",
      "description": "Safai XYZ is a cleaning services platform that caters to both residential and commercial spaces. The website provides users with an intuitive interface to book cleaning services, explore service packages, and manage their appointments efficiently.",
      "technology": {
        "backend": ["Ruby on Rails"],
        "frontend": ["React"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["sqlite3"]
      },
      "challenges": [
        "Users needed a hassle-free way to book cleaning services with flexible scheduling.",
        "Handling multiple service requests while ensuring cleaners’ availability.",
        "Ensuring smooth navigation across different devices.",
        "Providing a secure and user-friendly payment gateway for transactions."
      ],
      "solutions": [
        "Implemented a step-by-step booking flow that allows users to select their preferred cleaning service, date, and time.",
        "Integrated a dynamic availability system, syncing service requests with cleaner schedules to prevent overbooking.",
        "Used Tailwind CSS and Bootstrap to create a fully responsive UI, optimizing the experience for both desktop and mobile users.",
        "Integrated secure online payment options with proper encryption to ensure safe transactions."
      ]
    }
  },
  {
    id: 8,
    image: optelmaImage,
    title: "OPTELMA",
    description: "Optelma Lighting Solutions seeks a website redesign to improve user experience, showcase products, and generate leads. Objectives include enhanced navigation, product showcase, content management, lead generation, SEO optimization, and responsive design.",
    details:{
      "title": "Optelma Lighting Solutions Website Redesign Case Study",
      "description": "Optelma Lighting Solutions required a website redesign to enhance user experience, product showcasing, and lead generation. The goal was to improve navigation, content management, SEO optimization, and responsive design to attract potential customers and increase conversions.",
      "technology": {
        "backend": ["Ruby on Rails"],
        "frontend": ["React (for interactive components)"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["PostgreSQL"]
      },
      "challenges": [
        "The previous website had a cluttered interface and complex navigation, making it difficult for users to find products.",
        "Displaying a wide range of lighting products in a structured and visually appealing manner.",
        "The old site lacked effective lead capture forms and CTAs.",
        "The website had poor search engine rankings due to improper meta tags, slow loading times, and a lack of structured content."
      ],
      "solutions": [
        "Redesigned the navigation structure using React components and Tailwind CSS, improving menu hierarchy, product categories, and filters for easier browsing.",
        "Implemented dynamic product pages using Rails Active Storage to manage images and React-based UI components for an engaging user experience. Integrated a CMS system for easy content updates.",
        "Integrated interactive contact forms with validation and lead tracking mechanisms in Rails, ensuring smooth inquiry handling and lead collection.",
        "Applied SEO best practices, including optimized metadata, schema markup, fast-loading images, and clean URL structures, improving site performance and search rankings."
      ]
    }
  },
  {
    id: 9,
    image: gerenciaImage,
    title: "GERENCIA RED",
    description: "Gerencia Red is a web-based network management tool designed to streamline the monitoring and administration of complex network infrastructures. With a user-friendly interface and powerful features, Gerencia Red empowers network administrators.",
    details:{
      "title": "Gerencia Red – Network Management Tool Case Study",
      "description": "Gerencia Red is a web-based network management tool designed to streamline monitoring and administration of complex network infrastructures. With a user-friendly interface and powerful features, it helps network administrators efficiently manage and troubleshoot network performance.",
      "technology": {
        "backend": ["Ruby on Rails"],
        "frontend": ["React Native"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["PostgreSQL"]
      },
      "challenges": [
        "Processing and displaying live network data without performance issues.",
        "Presenting network analytics and logs in an intuitive manner.",
        "Ensuring the security of network data and preventing unauthorized access.",
        "The application needed to be accessible on both desktop and mobile devices."
      ],
      "solutions": [
        "Implemented WebSockets for real-time updates and optimized database queries with Rails ActiveRecord to handle large volumes of data efficiently.",
        "Designed interactive dashboards using React components, offering graphical data visualization and real-time alerts for better user experience.",
        "Integrated role-based authentication (Devise for Rails) and secure API communication (JWT-based authentication) to protect sensitive data.",
        "Used Tailwind CSS and Bootstrap to ensure a fully responsive design, making it easy to navigate across different screen sizes."
      ]
    }
  },
  {
    id: 10,
    image: VocaddImage,
    title: "VOCADD",
    description: "VocAdd is a vocational skills enhancement platform designed to empower individuals with essential job-related skills. It offers a range of online courses, workshops, and resources tailored to various industries and career paths, with a focus on enhancing employability.",
    details:{
      "title": "VocAdd – Vocational Skills Enhancement Platform Case Study",
      "description": "VocAdd is a vocational skills enhancement platform designed to empower individuals with job-related skills. It provides online courses, workshops, and resources tailored to various industries, helping users improve their employability and career prospects.",
      "technology": {
        "backend": ["Ruby on Rails"],
        "frontend": ["React Native"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["PostgreSQL"]
      },
      "challenges": [
        "The platform needed an engaging and user-friendly course delivery system.",
        "Handling a large volume of courses, lessons, and user progress tracking.",
        "Protecting user data and ensuring seamless course progress tracking.",
        "Users needed access across desktop, tablet, and mobile devices."
      ],
      "solutions": [
        "Used React Native for dynamic UI components and integrated video, quizzes, and interactive exercises for an immersive learning experience.",
        "Built a scalable content management system (CMS) using Rails Active Storage, allowing admins to upload, organize, and update courses easily.",
        "Implemented Devise for authentication and session-based tracking to allow users to continue learning where they left off.",
        "Used Tailwind CSS & Bootstrap to ensure a fully responsive and mobile-friendly learning experience."
      ]
    }
  },
  {
    id: 11,
    image: moveUImage,
    title: "Move U",
    description: "Move U is a fitness app designed to help users achieve their health and wellness goals through personalized workout plans, nutrition tracking, and community support. It offers a user-friendly interface, customizable workouts, progress tracking, and social features to motivate and engage users on their fitness journey.",
    details:{
      "title": "Move U – Fitness & Wellness App Case Study",
      "description": "Move U is a fitness and wellness app designed to help users achieve their health goals through personalized workout plans, nutrition tracking, and community support. With a user-friendly interface and engaging social features, the app keeps users motivated on their fitness journey.",
      "technology": {
        "backend": ["Ruby on Rails"],
        "frontend": ["React Native"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["PostgreSQL"]
      },
      "challenges": [
        "Users needed customized workout plans based on their goals and progress.",
        "Enabling users to track meals, calories, and macronutrients efficiently.",
        "Keeping track of workouts, completed exercises, and progress over time.",
        "Encouraging user interaction and motivation through community support."
      ],
      "solutions": [
        "Developed a modular workout builder in React Native, allowing users to select and modify workout routines dynamically.",
        "Implemented a structured input system with a user-friendly meal log, making it easier to enter and review nutrition data.",
        "Integrated a progress dashboard with historical tracking, allowing users to visualize their fitness journey with graphs and stats.",
        "Added social features like user groups, achievements, and a feed for sharing progress, enhancing motivation and engagement."
      ]
    }
  },
  {
    id: 12,
    image: eventImage,
    title: "EVENTAPP",
    description: "EventHive is an innovative event management app designed to streamline the planning and organization of both small-scale and large-scale events. It offers features such as event scheduling, attendee management, real-time communication, and analytics, providing event organizers with the tools they need to create successful events.",
    details:{
      "title": "EventHive – Event Management App Case Study",
      "description": "EventHive is an event management application designed to simplify the planning, scheduling, and execution of events. It provides event organizers with essential tools such as event scheduling, attendee management, real-time communication, and analytics to ensure seamless event execution.",
      "technology": {
        "backend": ["Ruby on Rails"],
        "frontend": ["React Native"],
        "Styling": ["CSS", "Tailwind CSS", "Bootstrap"],
        "markup": ["HTML"],
        "databases": ["PostgreSQL"]
      },
      "challenges": [
        "Organizers needed an intuitive way to create, update, and manage event schedules.",
        "Managing attendee registrations and ensuring a smooth check-in process.",
        "Event organizers needed a way to communicate with attendees instantly.",
        "Handling a high number of attendees and ensuring smooth performance during peak traffic."
      ],
      "solutions": [
        "Developed a drag-and-drop event scheduler with an interactive UI, allowing users to manage sessions, timings, and venue allocations easily.",
        "Integrated a QR-based check-in system that allowed attendees to register online and scan their QR codes upon arrival for quick verification.",
        "Implemented in-app messaging, push notifications, and email updates for real-time communication between organizers and attendees.",
        "Optimized database queries and API calls to handle large datasets efficiently and implemented server-side caching for better scalability."
      ]
    }
  }
]

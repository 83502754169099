import React from "react";
import { Link } from "react-router-dom";
import { portfolioData } from "../DummyJsonData/PortfolioData";

function Portfolio() {
  return (
    <>
      <section id="portfolio" className="portfolio sections-bg">
      <div className="breadcrumbs">
        <nav>
          <div className="container">
            <ol>
              <li><Link to="/">Home</Link></li>
              <li>Portfolio</li>
            </ol>
          </div>
        </nav>
      </div>
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Portfolio</h2>
            <p>
              At Ryientsoft Tech, we specialize in delivering cutting-edge software solutions tailored to meet the unique needs of our clients.
              Our portfolio showcases a diverse range of projects that highlight our expertise in innovative technology, 
              custom software development, and exceptional client service.
            </p>
          </div>
          <div
            className="portfolio-isotope"
            data-portfolio-filter="*"
            data-portfolio-layout="masonry"
            data-portfolio-sort="original-order"
            data-aos-delay="100"
          >
            <div className="row gy-4 portfolio-container">
              {portfolioData &&
                portfolioData.map((data) => (
                  <div key={data.id} className="col-xl-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      <Link className="text-decoration-none" to="/portfolio-details" state={{ details: data     }}>
                      <div data-gallery="portfolio-gallery-app" className="glightbox">
                          <img src={data.image} className="img-fluid" alt={data.title} />
                        </div>
                        <div className="portfolio-info">
                          <h4 title="More Details">
                            {data.title}
                          </h4>
                          <p>{data.description}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Portfolio;
import React from "react";
import IsoImage1 from "../assets/img/certification/iso-9001-2015-1-ry-659b895255884.webp";
import IsoImage2 from "../assets/img/certification/iso-27001-2017-1-ry-659b895193bc3.webp";

const IsoCertification = () => {
  return (
    <section id="services" className="services bg-gray-100 py-12">
      <div className="container mx-auto px-6" data-aos="fade-up">
        <div className="text-center mb-8 section-header">
          <h2 className="text-3xl font-bold text-gray-800 gradient-text-color">ISO Certification</h2>
          <p className="text-gray-600 mt-2 max-w-2xl mx-auto">
          At RyientSoft Technologies, we take pride in our professional recognition and reputation as a trusted name for all your business solution needs. Rely on us for expert guidance and exceptional results.
          </p>
        </div>
        <div className="d-flex justify-content-center items-center gap-5 flex-wrap">
          <img src={IsoImage1} alt="ISO 9001:2015 Certification" className="w-32 h-32 rounded-lg shadow-md object-contain" style={{height: "200px"}} />
          <img src={IsoImage2} alt="ISO 27001:2022 Certification" className="w-32 h-32 rounded-lg shadow-md object-contain" style={{height: "200px"}} />
        </div>
      </div>
    </section>
  );
};

export default IsoCertification;
import React from "react";
import python1 from "../assets/img/Python1.png";
import react from "../assets/img/React.webp";
import js from "../assets/img/js.webp";
import flutter from "../assets/img/f-removebg-preview.png";
import django from "../assets/img/dj.png";
import rails from "../assets/img/rails.png";
import next from "../assets/img/ne-removebg-preview.png";
import bootstrap from "../assets/img/bootstarp1.png";
import node from "../assets/img/node1.png";
import ios from "../assets/img/app-removebg-preview.png";
import android from "../assets/img/android-logo-removebg-preview.png";
import abo from "../assets/img/pexels-yankrukov-7793688.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function About() {
  return (
    <section id="services" className="services sections-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>About Us</h2>
          <p>
            Welcome to Ryientsoft Technology, where innovation meets excellence. We
            are a cutting-edge custom software development company dedicated to
            transforming your visionary ideas into digital reality. Our passion
            lies in crafting bespoke software solutions that not only meet your
            business needs but also propel your growth to new heights.
          </p>
        </div>

        <div className="gy-4" data-aos="fade-up" data-aos-delay="100">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="service-item ">
                <div className="icon">
                  <i className="bi bi-eye"></i>
                </div>

                <h3>Vision</h3>
                <p>
                  Our Vision is to revolutionize business processes and minimize
                  failure rates by delivering state-of-the-art software projects
                  with standardized approaches, Guidance, and technology.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-flag"></i>
                </div>
                <h3>Mission</h3>
                <p>
                  At RyientSoft Technology, our mission is to drive innovation and
                  excellence in software development by delivering cutting-edge
                  solutions that empower businesses to achieve their digital
                  transformation goals.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-airplane-fill"></i>
                </div>
                <h3>Goals</h3>
                <p>
                  Our goal is to be acknowledged as a single point of delivery
                  for all IT needs from start-ups to SMEs,s to Enterprises with
                  all repute globally.
                </p>
              </div>
            </div>
          </div>

          <div className="container" data-aos="fade-up">
            <div className="section-header1">
              <h2>Technologies We Master</h2>
            </div>

            <div className="gy-4  " data-aos="fade-up" data-aos-delay="100">
              <div className="row">
                <div className="col-lg-3 col-md-6 ">
                  <div className="service-item  position-relative">
                    <div className="icon-skills">
                      <img src={react} alt="/"></img>
                    </div>
                    <h3 className="language">React.Js</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={next} alt="/"></img>
                    </div>
                    <h3 className="language">Next.Js</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={js} alt="/"></img>
                    </div>
                    <h3 className="language">JavaScripts</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={bootstrap} alt="/"></img>
                    </div>
                    <h3 className="language">BootStrap</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={rails} alt="/"></img>
                    </div>
                    <h3 className="language">Ruby</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={python1} alt="/"></img>
                    </div>
                    <h3 className="language">Python</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={node} alt="/"></img>
                    </div>
                    <h3 className="language">Node.JS</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={django} alt="/"></img>
                    </div>
                    <h3 className="language">Django</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={react} alt="/"></img>
                    </div>
                    <h3 className="language">React-Native</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={flutter} alt="/"></img>
                    </div>
                    <h3 className="language">Flutter</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={ios} alt="/"></img>
                    </div>
                    <h3 className="language">IOS</h3>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="service-item position-relative">
                    <div className="icon-skills">
                      <img src={android} alt="/"></img>
                    </div>
                    <h3 className="language">Android</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section-header1">
            <h2>Why Choose Us</h2>
          </div>
          <div className="sss">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <img
                  src={abo}
                  alt="About"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="col-lg-8 col-md-6">
                <h1
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "navy",
                    marginTop: "40px",
                  }}
                >
                  Transform your vision into reality with our world-class
                  software development team.
                </h1>

                <div className="icon-star">
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Convert Ideas to Designs.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Enhanced User Experience.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Unprecedented Return of
                    Investments.
                  </li>
                </div>

                <div className="icon-star">
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Exponential Productivity.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Competitive Excellence.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Exuberant Customer
                    Experience.
                  </li>

                  <li>
                    <FontAwesomeIcon icon={faStar} /> Agile Technology Adoption.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Sustainable Growth.
                  </li>

                  <li>
                    <FontAwesomeIcon icon={faStar} /> Higher ROI.
                  </li>
                </div>
              </div>
            </div>

            <div className=""></div>
          </div>
          {/* <div style={{ display: "flex", marginTop: "150px" }}>
            <div style={{ flex: "4" }}>
              <img
                src={abo}
                alt="About"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div
              style={{
                flex: "6",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div style={{ margin: "0 80px 0" }}>
                <h1
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "navy",
                    marginTop: "40px",
                  }}
                >
                  Transform your vision into reality with our world-class
                  software development team. Our award-winning expertise ensures
                  that even the most intricate projects are handled with
                  precision and innovation. Let's bring your ideas to life!
                </h1>
                <p
                  style={{
                    marginTop: "30px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  We have All the Answers….
                </p>
                <div className="icon-star">
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Convert Ideas to Designs.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Enhanced User Experience.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Unprecedented Return of
                    Investments.
                  </li>
                </div>

                <p
                  style={{
                    marginTop: "30px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Why Choose Us….
                </p>
                <div className="icon-star">
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Exponential Productivity.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Competitive Excellence.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Exuberant Customer
                    Experience.
                  </li>

                  <li>
                    <FontAwesomeIcon icon={faStar} /> Agile Technology Adoption.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStar} /> Sustainable Growth.
                  </li>

                  <li>
                    <FontAwesomeIcon icon={faStar} /> Higher ROI.
                  </li>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default About;

import React from 'react'
function DigitalMarketing() {
  return (
    <section id="services" className="services sections-bg">
    <div className="container" data-aos="fade-up">
      <div className="section-header">
        <h2>Digital Marketing</h2>
        <p>Digital marketing refers to the use of digital channels and
           technologies to promote or market products and services to consumers and businesses.
           It encompasses a broad range of marketing activities that leverage online platforms 
           to connect with customers, engage audiences, and drive sales.
        </p>
      </div>

      <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
        <div className="col-lg-4 col-md-6">
          <div className="service-item  ">
          <div className="icon">
             <i className="bi bi-code-slash"></i>
             
            </div>
           
            <h3  >Affiliate Marketing</h3>
            <p>
            Affiliate marketing is a performance-based strategy where businesses reward partners (affiliates) for driving traffic, leads, or sales to their site. Affiliates promote products using unique links and earn commissions for resulting actions.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="service-item ">
          <div className="icon">
             <i className="bi bi-bullseye"></i>
             
            </div>
            <h3  >Re-targeting/Re-Marketing</h3>
            <p>A digital marketing strategy that re-engages users who previously visited a website or interacted with a brand but did not complete a desired action, using methods like re-targeting and other techniques to reconnect with past customers or leads.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="service-item ">
          <div className='icon'>
          <i className="bi bi-compass"></i>
            </div>
            <h3    >Behavioral Targeting /Audience Redirection</h3>
            <p>Behavioral targeting collects data on an individual's online behavior to deliver personalized ads and content, increasing engagement and conversions by creating relevant marketing messages.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="service-item ">
          <div className='icon'>
          <i className="bi bi-record-circle"></i>
            </div>
            <h3  >Co-Registeration</h3>
            <p>Co-registration is a strategy where users can sign up for additional offers from third-party advertisers during a website's registration process, generating leads by sharing user bases with partner companies.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="service-item ">
          <div className='icon'>
          <i className="bi bi-badge-ad-fill"></i>
            </div>
            <h3  > Contextual Advertising</h3>
            <p>Contextual advertising displays ads based on the content of the webpage a user is viewing. Instead of using user data, it relies on keywords and themes to determine the most relevant ads to show, enhancing relevance and engagement.






</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="service-item ">
          <div className='icon'>
          <i className="bi bi-facebook"></i>
            </div>
            <h3  >Social Media Marketing</h3>
            <p className='text-justify'>Social media marketing uses platforms like Facebook, Instagram, and Twitter to promote products, engage with audiences, and build brand awareness. It involves tailored content creation, targeted ads, and analytics to measure performance and refine strategies.</p>
          </div>
        </div>
      </div>
    </div>

  </section>
  )
}

export default DigitalMarketing
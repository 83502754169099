import React from 'react'
import { Link } from 'react-router-dom'

function ItStaffServices() {
  return (
    <section id="services" className="services bg-gray-100 py-12">
      <div className="container mx-auto px-6" data-aos="fade-up" style={{ marginBottom: "100px" }}>
        <div className="text-center mb-8 section-header">
          <h2 className="text-3xl font-bold text-gray-800 gradient-text-color">IT Staff Augmentation Services - Hire Skilled Developers</h2>
          <p className="text-gray-600 mt-2 max-w-2xl mx-auto">
            Expanding your IT team can be challenging, but with IT staff augmentation, you get skilled professionals without the hassle of long hiring processes. Whether you need backend developers, frontend experts, or cloud specialists, we provide on-demand talent to match your project needs. As a trusted IT outsourcing company in India, we help businesses scale efficiently. From short-term projects to long-term collaborations, our IT contract staffing solutions ensure flexibility and cost-effectiveness. Whether you want to hire dedicated developers or outsource software developers, we offer top-tier IT professionals to accelerate your growth.
          </p>
        </div>
        <div className="text-center mt-6" style={{ marginTop: "20px" }}>
          <Link
            to="/contact"
            className="inline-flex items-center px-6 py-3 no-underline"
            style={{ textDecoration: "none", color: "#D94B93", fontSize: "20px" }}
          >
            Request a Proposal <i class="bi bi-arrow-right-circle"></i>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ItStaffServices
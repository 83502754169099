import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";

function PortfolioDetails() {
  const location = useLocation();
  const PortfolioDetails = location.state?.details;
  return (
    <>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Portfolio Details</h2>
                {/* <p>Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit quaerat ipsum dolorem.</p> */}
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/portfolio">Portfolio</Link></li>
              <li>Portfolio Details</li>
            </ol>
          </div>
        </nav>
      </div>

      {/* <!-- ======= Portfolio Details Section ======= --> */}
      <section id="portfolio-details" className="portfolio-details">
        <div className="container" data-aos="fade-up">
          <div className="position-relative h-100">
            <div className="slides-1 portfolio-details-slider swiper">
              <div className="swiper-wrapper align-items-center">
                <div className="swiper-slide">
                  <img src={PortfolioDetails.image} alt="" />
                </div>
              </div>
              <div className="swiper-pagination"></div>
            </div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>

            <div className="row justify-content-between gy-4 mt-4">
              <div className="col-lg-8">

                <div className="col-lg-12">
                  <div className="portfolio-description">
                    <h2>{PortfolioDetails.details.title}</h2>
                    <p>
                      {PortfolioDetails.details.description}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="portfolio-description">
                    <h2>Challenges</h2>
                    <p>
                      {PortfolioDetails && PortfolioDetails?.details?.challenges.map((data, index) => (
                        <li key={index}>{data}</li>
                      ))}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="portfolio-description">
                    <h2>Solutions</h2>
                    <p>
                      {PortfolioDetails && PortfolioDetails?.details?.solutions.map((data, index) => (
                        <li key={index}>{data}</li>
                      ))}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="portfolio-info">
                  <h3>Technologies Used</h3>
                  <ul>
                    <li><strong>Backend:</strong><span>{PortfolioDetails?.details?.technology?.backend?.map((tech) => tech).join(" ")}</span>
                    </li>
                    <li><strong>Frontend:</strong> <span>{PortfolioDetails?.details?.technology?.frontend?.map((tech) => tech).join(" ")}</span></li>
                    <li><strong>MarkUp:</strong> <span>{PortfolioDetails?.details?.technology?.markup?.map((tech) => tech).join(" ")}</span></li>
                    <li><strong>Databases:</strong> <span>{PortfolioDetails?.details?.technology?.databases?.map((tech) => tech).join(" ")}</span></li>
                    <li><strong>Styling:</strong> <span>{PortfolioDetails?.details?.technology?.styling?.map((tech) => tech).join(" ")}</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PortfolioDetails